<template>
  <div class="outer-style">
    <div>课程学员使用情况</div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>

.outer-style {
  height: calc(100% - 50px);
  overflow: auto;
}
</style>